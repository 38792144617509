import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

interface Execution {
  duration: string;
  guestId: string;
  id: string;
  location: string;
  lastExecutionDateTime: string;
  memberId: string;
  memberName: string;
  workoutTitle: string;
  ipAddress: string;
}

export const GuestExecutionsShow = () => {
  const [data, setData] = useState<Execution[]>([]);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();
  const { id } = useParams();

  useEffect(() => {
    // Clear previous data to avoid showing old data
    setData([]);

    // Fetch new data based on the id
    dataProvider
      .getList(`guestExecutions/${id}`, {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        console.log("Fetched Data:", data);
        setData(data); // Set the latest data
      })
      .catch((error) => {
        console.error("Error fetching challenges:", error);
        setError(error); // Set the error if the request fails
      });

  }, [id]); // Re-fetch data when the id changes


  if (data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Guest Executions</h2>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        {/* Header row for column labels */}
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Execution ID</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Guest ID</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Workout Title</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Duration</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Last Execution Date</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Location</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Member ID</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Member Name</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>IP Address</th>
          </tr>
        </thead>

        {/* Data rows */}
        <tbody>
          {data.map((execution) => (
            <tr key={execution.id}>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.id}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.guestId}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.workoutTitle || "N/A"}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.duration}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.lastExecutionDateTime}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.location}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.memberId || "N/A"}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.memberName || "N/A"}</td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>{execution.ipAddress || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
