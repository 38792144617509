import { useParams } from "react-router-dom";
import { useGetOne, useRedirect, Title, Loading, useGetResourceLabel, GET_ONE, useRecordContext, List } from "react-admin";
import {
  Avatar,
  Card,
  Divider,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  ListItemAvatar,
} from "@mui/material";
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";

export const GlobalChallengeShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    "globalChallenge",
    { id },

    { onError: () => redirect("/error") }
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledShow title={`Global Challenges ${data.name ? `\\ ${data.name}` : "\\ Global Challenge"}`}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <TextField source="id" label="Challenge Id" />
        <TextField source="communityId" label="Community Id" />
        <TextField source="creatorId" label="Creator Id" />
        <TextField source="creatorName" label="Creator Name" />
        <TextField source="name" label="Title" />
        <TextField source="description" label="Description" />
        <TextField source="companyAddress" label="Company Address" />
        <TextField source="daysLimit" label="Days Limit" />
        <TextField source="challengeStatus" label="Challenge Status" />
        <TextField source="challengeType" label="Challenge Type" />
        <TextField source="isShareable" label="Is Shareable" />
        <TextField source="shareLink" label="Share Link" />
        <TextField source="isDeleted" label="Is Deleted" />
        <TextField source="startDateTime" label="Start DateTime" />
        <TextField source="endDateTime" label="End DateTime" />
        <TextField source="createdDateTime" label="Created DateTime" />
        
        <Typography variant="h6" gutterBottom>
          Workouts
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Workout ID</TableCell>
                <TableCell>Workout Title</TableCell>
                <TableCell>Target Counts</TableCell>
                <TableCell>Workout Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.workouts?.map((workout: any) => (
                <TableRow key={workout.workoutId}>
                  <TableCell>{workout.workoutId}</TableCell>
                  <TableCell>{workout.workoutTitle}</TableCell>
                  <TableCell>{workout.targetCounts}</TableCell>
                  <TableCell>{workout.workoutTime} mins</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
          <Typography variant="h6" style={{ margin: "1rem" }}>
            Members
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Member ID</TableCell>
                <TableCell>Member Name</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Joined Date</TableCell>
                <TableCell>Progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.members?.map((member: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{member.memberId}</TableCell>
                  <TableCell>{member.memberName}</TableCell>
                  <TableCell>
                    <Avatar src={member.memberPhoto} alt={member.memberName} />
                  </TableCell>
                  <TableCell>{new Date(new Date(0).setUTCFullYear(1) + member.joinedDateTime.date / 10000).toLocaleDateString()}</TableCell>
                  <TableCell>{member.progress.percentage && member.progress.percentage > 0 ? member.progress.percentage : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom>
          Share Links
        </Typography>
        {data?.shareLinks?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.shareLinks?.map((sharelink: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{sharelink.link}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Share Links available.
          </Typography>
        )}
      </SimpleShowLayout>
    </StyledShow>
  );
};
