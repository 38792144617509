import { List, Datagrid, TextInput, WrapperField } from 'react-admin';
import { TextField, DateField } from 'react-admin';

const memberFilters = [
    <TextInput label="Name" source="name" defaultValue="" />,
    <TextInput label="Email" source="email" />
];


export const MemberList = () => (

    <List filters={memberFilters} sort={{ field: 'createDateTimeDisplay', order: 'DESC' }}>

        <Datagrid rowClick="show"
            sx={{
                '& .RaDatagrid-headerCell': {
                    backgroundColor: '#f0e9f2', fontWeight: 'bold', fontFamily: 'inherit'
                },
            }}
            bulkActionButtons={false}
        >
            <TextField source="id" />
            <WrapperField label="Full Name" sortBy="name" >
                <TextField source="name" /><br></br>
                <TextField source="id" /><br></br><br></br>
                <TextField label="Email" source="email" />
            </WrapperField>

            <DateField source="createDateTimeDisplay" label="Registration Date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <DateField source="executionDateTimeDisplay" label="Last Execution" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <DateField source="postDateTimeDisplay" label="Last Post" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />

            <TextField label="Executions" source="totalExecutions" />
            <TextField label="Posts" source="totalPosts" />
            <TextField label="Location" source="location" />

        </Datagrid>
    </List>

);