import { useParams } from "react-router-dom";
import { useGetOne, useRedirect, Title, Loading, useGetResourceLabel, GET_ONE, useRecordContext, List } from "react-admin";
import {
  Avatar,
  Card,
  Divider,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";

export const CommunityShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    "community",
    { id },

    { onError: () => redirect("/error") }
  );
  if (isLoading) {
    return <Loading />;
  }


  const renderMembers = () => (
    <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
      <Typography variant="h6" style={{ margin: "1rem" }}>
        Members
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Member ID</TableCell>
            <TableCell>Member Name</TableCell>
            <TableCell>Photo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.members?.map((member: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{member.memberId}</TableCell>
              <TableCell>{member.memberName}</TableCell>
              <TableCell>
                <Avatar src={member.memberPhoto} alt={member.memberName} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderBookmarkedWorkouts = () => {
    if (!data?.bookmarkedWorkouts || data.bookmarkedWorkouts.length === 0) {
      return (
        <>
          <Typography variant="h6" color="textSecondary">
            Bookmarked Workouts
          </Typography>
          <Typography variant="body2" color="textSecondary">
            No bookmarked workouts available.
          </Typography>
        </>
      );
    }

    return (<>
      <Typography variant="h6" style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
        Bookmarked Workouts
      </Typography>
      <List>
        {data.bookmarkedWorkouts.map((workout: string, index: number) => (
          <ListItem key={index}>
            <ListItemText primary={workout} />
          </ListItem>
        ))}
      </List>
    </>
    );
  };

  return (
    <StyledShow title={`Communities ${data.communityName ? `\\ ${data.communityName}` : "\\ Community"}`}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <TextField source="id" label="Community Id" />
        <TextField source="creatorId" label="Creator Id" />
        <TextField source="creatorName" label="Creator Name" />
        <TextField source="communityName" label="Name" />
        <TextField source="companyName" label="Company Name" />
        <TextField source="companyAddress" label="Company Address" />
        <TextField source="punch" label="Punch" />
        <TextField source="alias" label="Alias" />
        <TextField source="type" label="Type" />
        <TextField source="class" label="Class" />
        <TextField source="link" label="Link" />
        <TextField source="logo" label="Logo" />
        <TextField source="banner" label="Banner" />
        <TextField source="isDeleted" label="Is Deleted" />
        {renderBookmarkedWorkouts()}
        {renderMembers()}
        <TextField source="createdDateTime" label="Created Date Time" />
        <TextField source="createdTimezoneOffset" label="Created Timezone Offset" />
        <TextField source="createdDateTimeWithTimezone" label="Created Date Time With Timezone" />
        <TextField source="modifiedDateTime" label="Modified Date Time" />
        <TextField source="modifiedTimezoneOffset" label="Modified Timezone Offset" />
        <TextField source="modifiedDateTimeWithTimezone" label="Modified Date Time With Timezone" />
        <TextField source="isOfficial" label="Is Official" />
        <TextField source="membersCount" label="Members Count" />
        <TextField source="bookmarkedWorkoutsCount" label="Bookmarked Workouts Count" />
      </SimpleShowLayout>
    </StyledShow>
  );
};
