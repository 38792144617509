import { ForkRight, Label } from "@mui/icons-material";
import { positions } from "@mui/system";
import { values } from "lodash";
import * as React from "react";
import { SimpleForm, Edit, TextInput, SelectInput, SelectArrayInput, ReferenceInput, EditButton, Toolbar, SaveButton, ShowButton, Button, TopToolbar, EditActions, editFieldTypes } from 'react-admin';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
// import {JsonInput} from '@bb-tech/ra-components';
import { JsonField, JsonInput } from "react-admin-json-view";
import { SystemWorkoutEdit } from "./SystemWorkoutEdit";



const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />

    </Toolbar>
);

export const WorkoutPlanEdit = (props: any) => {

    return (

        <Edit {...props} title='Edit System Workout Schema' actions={false} >

            <SimpleForm toolbar={<><UserEditToolbar /></>} >

                <JsonInput source="workoutRawContent" jsonString={true} label="Workout Plan"
                    reactJsonOptions={{ collapsed: true, enableClipboard: false, displayDataTypes: false }} />

                <TextInput
                    source="referrerNote"
                    label="Referrer Note"
                />

            </SimpleForm>

        </Edit>
    );

};